// components
import LazyImage from "@components/LazyImage";
import { SERVICE_URL } from "../config";

const FileCard = ({ name, detail, src, a }) => {
  return (
    <div
      className="card h-1 d-flex flex-column align-items-center g-12 card-padded border-color-bottom"
      style={{ borderColor: `var(--olive)` }}
    >
      <a href={SERVICE_URL+a} target="_blank" rel="noopener noreferrer">
        <LazyImage
          className="club-logo club-logo--xl"
          src={"https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png"}
          alt={"pdf"}
        />
        <div className="d-flex flex-column align-items-center g-4">
          <h3>{name}</h3>
          <span className="text-12 text-overflow">{detail}</span>
        </div>
      </a>
    </div>
  );
};

export default FileCard;
