// components
import PageHeader from "@layout/PageHeader";
import AppGrid from "@layout/AppGrid";
import WidgetGroup from "@components/WidgetGroup";
import FileCard from "@components/FileCard";

const widgets = {
  doc1: (
    <FileCard
      name="Regimen Especial"
      detail="R.E"
      a="static/regimen_especial.pdf"
    />
  ),
  doc2: (
    <WidgetGroup>
      <FileCard
        name="Ajuste Presupuestal"
        detail="Ajuste Presupuestal"
        a="static/ajuste_prosupuestal.pdf"
      />
      <FileCard
        name="Presuspuestos"
        detail="Presuspuestos"
        a="static/presupuestos.pdf"
      />
    </WidgetGroup>
  ),

  doc4: (
    <WidgetGroup>
      <FileCard
        name="Certificado de Permanencia ESAL"
        detail="Certificado de Permanencia ESAL"
        a="static/esal.pdf"
      />
      <FileCard
        name="Distamen revisoria fiscal"
        detail="D.R.F"
        a="static/distamen_revisoria_fiscal.pdf"
      />
    </WidgetGroup>
  ),
  doc5: (
    <FileCard
      name="Firma Electronica"
      detail="Firma Electronica"
      a="static/firma_electronica.pdf"
    />
  ),
  doc6: (
    <FileCard
      name="Estados Financieros 2024"
      detail="Estados Financieros 2024"
      a="static/estados_financieros.pdf"
    />
  ),
};

const Dian = () => {
  return (
    <>
      <PageHeader title="Información DIAN" />
      <AppGrid id="dian" widgets={widgets} />
    </>
  );
};

export default Dian;
