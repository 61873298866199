// styling
import styles from './styles.module.scss';

// components
import Submenu from '@ui/Submenu';
import SettingsPopup from '@layout/BottomNav/SettingsPopup';

// hooks
import useSubmenu from '@hooks/useSubmenu';
import {useWindowSize} from 'react-use';
import {useState} from 'react';
import useStoreRoute from '@hooks/useStoreRoute';
import {useShopProvider} from '@contexts/shopContext';

// assets

import {useNavigate} from 'react-router-dom';


//context 
import { useAuth } from '@contexts/authContext';

const User = () => {
    const { logout,isLoggedIn,userData } = useAuth();
    const navigate = useNavigate();

    const [popupOpen, setPopupOpen] = useState(false);
    const {anchorEl, open, handleClick, handleClose} = useSubmenu();
    const isTablet = useWindowSize().width < 1280;
    const isStoreRoute = useStoreRoute();
    const {setCartOpen} = useShopProvider();

    const settingsPopup = {
        label: 'UI Settings',
        icon: 'gear-solid',
        onClick: () => setPopupOpen(true)
    }

    const submenuActions = [
        {
            label: 'Mi perfil',
            icon: 'user',
            onClick : () => navigate('/settings')
        },{
            label: 'Ver carnet',
            icon: 'pdf',
            onClick : () =>  navigate('/license') 
        },
        {
            label: 'Ayuda/Soporte técnico',
            icon: 'envelope',
            onClick : () => window.open("https://wa.link/hrfllh", "_blank")
        },
        {
            label: 'Salir',
            icon: 'exit',
            onClick : () => logout()
        }
    ];



    return (
        <div className="d-flex align-items-center g-16">
            <div className={styles.avatar}>
            { isLoggedIn && ( <img className="c-pointer"  crossOrigin="anonymous" src={userData?.image} alt="user" onClick={handleClick}/>) }
               
                {
                    isStoreRoute && isTablet && (
                        <button className={styles.avatar_cart} aria-label="Shopping cart" onClick={() => setCartOpen(true)}>
                            <i className="icon-bag-solid"/>
                        </button>
                    )
                }
            </div>
            { isLoggedIn ? (
                <>
                 <div className="d-flex flex-column">
                <span className="h3" style={{letterSpacing: 0.2}}>
                    {userData?.name +' '+ userData.lastname}
                </span>
                <span className="text-12">{userData?.teamname}</span>
            </div>
            <Submenu open={open}
                     onClose={handleClose}
                     anchorEl={anchorEl}
                     actions={isTablet ? [settingsPopup, ...submenuActions] : submenuActions}/>
            <SettingsPopup open={popupOpen} onClose={() => setPopupOpen(false)}/>
                </>
               
            ) : (<>
                  <div className={styles.main_buttons} onClick={()=>navigate('/login')}>
                    <button className="btn btn--icon">
                        <i className="icon icon-users-two" /> Ingresar
                    </button>
                </div>
            </>)
            }
            
        </div>
    )
}

export default User